import React, { Component } from 'react';
import styled from 'styled-components';
import { Box, Title2, Title3, Large, colors } from 'js-components';
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper';
import { Helmet } from 'react-helmet';
import { PageTitle, PageTitle2, PageTitle3 } from '../../components/PageTitle';

import ConversationCloud from '../images/conversationCloudDiagram.svg';

import LayerConnector from '../images/LayerConnector.svg';
import LayerGlobal from '../images/layerGlobal.svg';
import LayerTelephony from '../images/layerTelephony.svg';
import LayerWorkflow from '../images/layerWorkflow.svg';
import ActionEvent from '../images/actionEvent.svg';
import { CallToAction, GetStarted } from '../../components/CallToAction'


import Default from '../../components/Layouts/Default';


export default class RevenueProcessAutomation extends Component {

  render() {
    return (
      <Default>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Truly | What is Activity Intelligence?</title>
          <meta
            name="description"
            content="With Revenue Process Automation, you can scale all of your core revenue generating activities, like activity tracking, sales coaching and pipeline management."
          />
        </Helmet>

        <WrapperBackground color={colors.trulyDark} background="telephony1">
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
                Eliminate Manual Data Entry
              </PageTitle2>
              <PageTitle color={colors.white} mb="1.5rem" center>
                Remove Bias and Inconsistency From The Sales Equaton
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperSolid>
          <Section flexDirection="column" py={['6rem', '3rem']} background={colors.trulyDark}>
            <Box width={[1, 2 / 3]}>
              <PageTitle2 as="h2" color={colors.trulyBlue} bold my="1.5rem">
                The Problem With Manual Data Entry
              </PageTitle2>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                <u><b>Not Enough Time</b></u>: Reps already spend 5 hours a week updating the CRM, but we still end up with an incomplete view of the funnel.  We train them, SPIF them, say 'or else', but there just isn't enough time to get us the information we want AND hit the sales number.
              </Large>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                <u><b>"Sales Process" Makes It Worse</b></u>: CRM is hard enough to use, but in the interest of process, we add even more complexity with required fields, validation rules, etc.  Reps being clever as they are find workarounds that cause your data to go from 'incomplete' to 'bad'.
              </Large>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                <u><b>It's All Subjective</b></u>: What is a "DM Connect" anyway?  One of our customers found that 37.5% of the time a rep says they connected with a Decision Maker, the conversation was unengaged with the customer speaking less than 2% of the time.  Structured data is meaningless with subjective interpretation.
              </Large>
            </Box>
            <br/><br/><br/>
           <PageTitle2 as="h2" color={colors.trulyBlue} bold my="1.5rem">
                Our Approach:
                <br/> 
                Eliminate Data Entry Completely
            </PageTitle2>
            <br/>
            <Box width={[1, 3/3]} mb={['32px', 0]}>
              <CenteredImg src={ActionEvent} alt="Salesforce process automation"/>

            </Box>
            <br/><br/><br/>
            <Box width={[1, 2/3]} mb={['32px', 0]}>
 
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                We've created the only 'zero touch' activity capture platform that captures calls, text messages, emails and web meetings without reps having to do a thing. 
                <br/><br/>
                We then transcribe, analyze and synthesize all of this conversational data, pushing it all into Salesforce to make it your single source of truth for all engagement data.
                <br/><br/>
                Finally, our customizable rules engine allows you to transform this data into standardized inputs across all of your CRM objects and fields, so you can get the right information into the right views automatically.
              </Large>
            </Box>

            <br/><br/><br/>
          </Section>

        </WrapperSolid>

        <Values>
          <Section flexDirection="column">
            <Title2 color={colors.accentFog} mt="32pt">
              5 Pillars Of Revenue Process Automation
            </Title2>
          </Section>
          <Section flexDirection={['column', 'row']}>
            <Box width={[1 / 3, 1 / 4]} pr={['0pt', '32pt']} pb={['32pt', '0pt']}>
              <WidthImg src={LayerConnector} alt="Complete Data"/>
            </Box>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                Zero Touch
              </PageTitle3>
              <Large center color={colors.white}>
                If your tools/process make reps take an extra step, then it's almost guaranteed you're not capturing all of your customer engagement data.
              </Large>
            </Box>
          </Section>
          <Section flexDirection="column">
            <Divider />
          </Section>
          <Section flexDirection={['column', 'row']}>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                Useful to All
              </PageTitle3>
              <Large center color={colors.white}>
                Activity Tracking is only 'big brother' when the information derived is accessible and useful to management alone.
                <br/><br/>
                You need to deliver high ROI to your reps, and put this front and center through end-user training.
            </Large>
            </Box>
            <Box width={[1 / 3, 1 / 4]} pl={['0pt', '32pt']} pt={['32pt', '0pt']}>
              <WidthImg src={LayerTelephony} alt="Centralized Data"/>
            </Box>
          </Section>
          <Section flexDirection="column">
            <Divider />
          </Section>
          <Section flexDirection={['column', 'row']}>
            <Box width={[1 / 3, 1 / 4]} pr={['0pt', '32pt']} pb={['32pt', '0pt']}>
              <WidthImg src={LayerGlobal} alt="Fully Customizable"/>
            </Box>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                Fully Customizable
              </PageTitle3>
              <Large center color={colors.white}>
                Every business has a unique product/market/segment/strategy combination.  Generic AI models can't possibly capture the many nuances that your team/leaders know about your customers.
                <br/><br/>
                Without this business knowledge, your analytics/automation solution will never be fully embraced or trusted by your team.
              </Large>
            </Box>
          </Section>
          <Section flexDirection="column">
            <Divider />
          </Section>
           <Section flexDirection={['column', 'row']}>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                In The Customer Voice
              </PageTitle3>
              <Large center color={colors.white}>
                The problem with call/deal notes is that they're all centered around the seller.  90% of the decisions we make is centered on what the customer cares about.
                <br/><br/>
                You need to be able to be able to target and synthesize what the customer cares about, in their voice, and make that information usable across your entire organization (sales, marketing, product, service.)
              </Large>
            </Box>
            <Box width={[1 / 3, 1 / 4]} pr={['0pt', '32pt']} pt={['32pt', '0pt']}>
              <WidthImg src={LayerWorkflow} alt="Real Time"/>
            </Box>
          </Section>
          <Section flexDirection="column">
            <Divider />
          </Section>
        </Values>
        <WrapperBackground background="headsetPerson1" gradientType="purple">
          <CallToAction/>
        </WrapperBackground>
        <WrapperBackground background="sf" gradientType="black">
          <GetStarted/>
        </WrapperBackground>
      </Default>
    );
  }
}

const Values = styled.div`
  background-color: #222;
  font-family: 'Josefin Sans';
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
`;

const CenteredImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`;

const WidthImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`;
